import { NavLink } from 'react-router-dom';

import styles from './DashboardCard.module.scss';

interface CardProps {
  title: string;
  cardNo: number;
  progress: number;
}

export default function DashboardCard({
  title,
  cardNo,
  progress,
}: CardProps): React.JSX.Element {
  // const progressColor =
  //   progress < 100
  //     ? 'var(--color-blue)'
  //     : 'var(--color-deep-purple-transparent-6)';

  return (
    <div className={styles.container}>
      <NavLink to={`/questionnaire/${cardNo}`} className={styles.navLink}>
        <h3>{title}</h3>
        {/* <p>{description}</p> */}
        {/* <div className={styles.progressBarContainer}>
          <div className={styles.progressBar}>
            <div
              className={styles.progressBarFill}
              style={{ width: `${progress}%`, backgroundColor: progressColor }}
            />
          </div>
          <p>{progress}%</p>
        </div> */}
      </NavLink>
    </div>
  );
}
