import { NavLink } from 'react-router-dom';

import styles from './Heading.module.scss';
import { LuFileEdit } from 'react-icons/lu';

interface HeadingProps {
  prompt: string | string[];
  isReport?: boolean;
  navDestination?: string;
}

export default function Heading({
  prompt,
  isReport = false,
  navDestination = '',
}: HeadingProps): React.JSX.Element {
  return (
    <div className={styles.container}>
      <h4>
        {prompt}
        {isReport && (
          <>
            <NavLink to={navDestination} className={styles.navLink}>
              <LuFileEdit className={styles.editIcon}></LuFileEdit>
              <p className={styles.editText}>Edit</p>
            </NavLink>
          </>
        )}
      </h4>
    </div>
  );
}
