import React, { type ReactNode } from 'react';

import styles from './ReportDisplayColumnLayout.module.scss';
// eslint-disable-next-line max-len
import ShortText from '../../../Questionnaire/PageComponent/QuestionComponents/QuestionTypes/ShortText/ShortText';

interface ReportDisplayColumnLayoutProps {
  customText?: string;
  children?: ReactNode;
  topMargin?: string | null;
  bottomMargin?: string | null;
}

const ReportDisplayColumnLayout: React.FC<ReportDisplayColumnLayoutProps> = ({
  customText,
  children,
  topMargin = null,
  bottomMargin = null,
}) => {
  const containerStyle1 =
    topMargin !== null ? { marginTop: `${topMargin ?? '0'}em` } : {};
  const containerStyle2 =
    topMargin !== null ? { marginBottom: `${bottomMargin ?? '0'}em` } : {};

  return (
    <div className={styles.container} style={{...containerStyle1, ...containerStyle2 }}>
      <ShortText maxWidth={'18%'} topMargin={'0'} prompt={customText}></ShortText>
      <div className={styles.displayRows}>{children}</div>
    </div>
  );
};

export default ReportDisplayColumnLayout;
