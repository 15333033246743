import { inject, observer } from 'mobx-react';

import PrevPageComponent from './PrevPageComponent/PrevPageComponent';
import NextPageComponent from './NextPageComponent/NextPageComponent';
import type RootStore from '../../../stores/RootStore';

import styles from './NavFooterComponent.module.scss';
import SaveDataComponent from './SaveDataComponent/SaveDataComponent';

interface NavFooterComponentProps {
  rootStore?: RootStore;
}

const NavFooterComponent: React.FC<NavFooterComponentProps> = observer(
  ({ rootStore }) => {
    const questionnaireStore = rootStore?.questionnaireStore;
    const currentPage = questionnaireStore?.currentPage;
    return (
      <>
        <div className={styles.container}>
          <div className={styles.navLinks}>
            <PrevPageComponent></PrevPageComponent>
            <NextPageComponent></NextPageComponent>
          </div>
          {currentPage !== 9 && <SaveDataComponent></SaveDataComponent>}
        </div>
      </>
    );
  },
);

export default inject('rootStore')(NavFooterComponent);
