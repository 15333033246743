import { Outlet, useLocation } from 'react-router-dom';

import Header from '../components/Header/Header';
import styles from './RootLayout.module.scss';

export default function RootLayout(): React.JSX.Element {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div className={styles.container}>
      {location.pathname !== '/login' && <Header />}
      <main className={isLoginPage ? styles.mainLogin : ''}>
        <Outlet></Outlet>
      </main>

      <footer></footer>
    </div>
  );
}
