import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import type RootStore from '../../../../../../stores/RootStore';
import { type ParaInputAnswer } from '../../../../../../types/userAnswer';
import TextLimitCounter from '../TextLimitCounter/TextLimitCounter';
import styles from './ParaInput.module.scss';

interface ParaInputProps {
  rows?: number;
  limit?: number;
  rootStore?: RootStore;
  questionnaireQuestionsId: number;
}

const ParaInput: React.FC<ParaInputProps> = observer(
  ({ rootStore, rows = 4, limit = 500, questionnaireQuestionsId }) => {
    const limitTolerance = 15;
    const userAnswersStore = rootStore?.userAnswersStore;
    const savedInput = userAnswersStore?.userPageAnswers[
      questionnaireQuestionsId
    ] as ParaInputAnswer;
    const [text, setText] = useState(savedInput?.text ?? '');
    useEffect(() => { setText(savedInput?.text ?? ''); }, [savedInput]);

    const handleTextChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => {
      const inputText = event.target.value;
      if (inputText.length <= limit + limitTolerance) {
        userAnswersStore?.setAnswerForQuestion(questionnaireQuestionsId, {
          text: inputText,
          type: 'para_text_input',
        });
        setText(inputText);
      }
    };

    return (
      <div className={styles.container}>
        <textarea
          rows={rows}
          value={text}
          onChange={handleTextChange}
        ></textarea>
        <TextLimitCounter count={text.length} limit={limit} />
      </div>
    );
  },
);

export default inject('rootStore')(ParaInput);
