import styles from './Button.module.scss';

// eslint-disable-next-line max-len
import { ReactComponent as MicrosoftLogo } from '../../assets/icons/components/Button/microsoft_logo.svg';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: 'microsoft' | 'google' | 'facebook' | 'twitter';
  width?: string;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, icon, width }) => {
  return (
    <button
      className={styles.container}
      onClick={onClick}
      style={{ flexBasis: width }}
    >
      {icon === 'microsoft' && <MicrosoftLogo className={styles.icon} />}
      {/* {icon === 'google' && <FaGoogle />}
      {icon === 'facebook' && <FaFacebook />}
      {icon === 'twitter' && <FaTwitter />} */}
      {children}
    </button>
  );
};

export default Button;
