import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0E0635',
    },
    secondary: {
      main: '#1B75BB',
    },
  },
  typography: {
    fontFamily: "'Azo Sans', Arial, sans-serif",
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '2.5em',
          marginTop: '0.5em',
          borderRadius: '2px',
        },
        input: {
          padding: '0.8em 0.5em',
        },
        notchedOutline: {
          borderColor: '#5D5D5D',
        },
      },
    },
  },
});
