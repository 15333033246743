import { useRef } from 'react';
import { useDrag } from 'react-dnd';

import { type Choice } from '../../../../../../../types/index';
import styles from './DraggableButton.module.scss';

interface DraggableButtonProps extends Choice {
  onDragEnd?: () => void;
}

const DraggableButton = ({
  id,
  text,
  onDragEnd,
}: DraggableButtonProps): React.JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag({
    type: 'draggableButton',
    item: { id, text },
    end: (item, monitor) => {
      if (monitor.didDrop() && (onDragEnd != null)) {
        onDragEnd();
      }
    },
  });

  drag(ref);

  return (
    <div ref={ref} className={styles.container}>
      <button>{text}</button>
    </div>
  );
};

export default DraggableButton;
