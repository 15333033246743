import { inject, observer } from 'mobx-react';

import type RootStore from '../../../../stores/RootStore';
import styles from './PageProgress.module.scss';

interface PageProgressProps {
  rootStore?: RootStore;
}

const PageProgress: React.FC<PageProgressProps> = observer(
  ({ rootStore }) => {
    const questionnairePageStore = rootStore?.questionnaireStore;
    const pageNo = questionnairePageStore?.currentPage ?? 1;
    const totalPages = questionnairePageStore?.totalPages ?? 0;

    return (
      <>
        <div className={styles.container}>
          { pageNo } of {totalPages + 1}
        </div>
      </>
    );
  },
);

export default inject('rootStore')(PageProgress);
