/* eslint-disable max-len */
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageComponent from './PageComponent/PageComponent';
import type RootStore from '../../stores/RootStore';
import LoadingComponent from '../../components/Loading/LoadingComponent';

interface QuestionnaireProps {
  rootStore?: RootStore;
}

const Questionnaire: React.FC<QuestionnaireProps> = observer(
  ({ rootStore }) => {
    const questionnairePageStore = rootStore?.questionnaireStore;

    const { pageNumber } = useParams();
    const navPageNo = Number(pageNumber);
    const initialPage = isNaN(navPageNo) ? 1 : navPageNo;

    useEffect(() => {
      if (questionnairePageStore != null) {
        questionnairePageStore.setCurrentPage(initialPage);

        void questionnairePageStore.fetchPages();
      }
    }, [initialPage]);

    const { loading = false, error = null } =
      rootStore?.questionnaireStore ?? {};

    if (loading) return <LoadingComponent />;
    if (error != null) return <div>{error}</div>;

    return (
      <>
        <PageComponent />
      </>
    );
  },
);

export default inject('rootStore')(Questionnaire);
