import React, { useState } from 'react';

import dayjs, { type Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import styles from './YearMonthDateInput.module.scss';
import { type DegreeCertRow } from '../../../../../../types/userAnswer';

interface YearMonthDateInputProps {
  label?: string;
  width?: string;
  rowNum: number;
  initialDate?: string | null;
  updateRow: (
    rowIndex: number,
    key: keyof DegreeCertRow,
    value: string | Date,
  ) => void;
}

export default function YearMonthDateInput({
  label,
  width,
  rowNum,
  updateRow,
  initialDate = '',
}: YearMonthDateInputProps): React.JSX.Element {
  const isMobile = window.innerWidth <= 768;

  // Initialize local state for date
  const [date, setDate] = useState<Dayjs | null>(
    initialDate != null ? dayjs(initialDate) : null,
  );

  // Function to handle date changes
  const handleDateChange = (newDate: Dayjs | null): void => {
    if (newDate !== null) {
      if (newDate.isValid()) {
        const isoFormattedDate = newDate.toISOString();
        updateRow(rowNum, 'dateCell', isoFormattedDate);
        setDate(newDate);
      } else {
        console.warn("Received an invalid date");
      }
    } else {
      // Handle null case here if needed
      console.warn("Received null date");
    }
  };

  return (
    <div
      className={styles.container}
      style={{ flexBasis: isMobile ? '100%' : width }}
    >
      <label htmlFor="dateInput"> {label} </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label=" "
          views={['month', 'year']}
          className={styles.customDatePicker}
          value={date}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </div>
  );
}
