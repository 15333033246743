import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type IPublicClientApplication } from '@azure/msal-browser';

import { useAuth } from '../contexts/AuthContext';

export const useLogout = (msalInstance: IPublicClientApplication):
{ logout: () => Promise<void>; error: unknown } => {
  const [error, setError] = useState<unknown>(null);

  const { setUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    try {
      console.debug('Logging out');

      // Logout the current user
      await msalInstance.logoutPopup();

      // Clear the user data from your application state
      setUser(null);

      clearLocalStorage();

      navigate('/login');
    } catch (logOutError) {
      console.error('Logout error:', logOutError);
      setError(logOutError);
    }
  };

  const clearLocalStorage = (): void => {
    localStorage.removeItem('user');
    localStorage.removeItem('totalPages');
    localStorage.removeItem('userId');
    localStorage.removeItem('pageLabels');
    localStorage.removeItem('questionnaireId');
    localStorage.removeItem('userQuestionnaireId');
  };

  return { logout: handleLogout, error };
};
