import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import styles from './PrevPageComponent.module.scss';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/components/left-arrow.svg';
import type RootStore from '../../../../stores/RootStore';

interface PrevPageComponentProps {
  rootStore?: RootStore;
}

const PrevPageComponent: React.FC<PrevPageComponentProps> = observer(
  ({ rootStore }) => {
    const navigate = useNavigate();
    const questionnaireStore = rootStore?.questionnaireStore;
    const prevNavLabel = questionnaireStore?.navLabels.prev;
    return (
      <div className={styles.container}>
        <div
          className={styles.navLink}
          onClick={() => questionnaireStore?.decrementPage(navigate)}
        >
          <ArrowLeft />
          <span>{prevNavLabel}</span>
        </div>
      </div>
    );
  },
);

export default inject('rootStore')(PrevPageComponent);
