import { type Choice } from "../types";
import { type DropSortAnswer } from "../types/userAnswer";

// Saving the sorting result to the store
export const convertBoxesToSortedItems = (
  boxes: Record<string, Choice[]>,
  dropCategories?: string[],
): Array<{ dropCategory: string; choices: Choice[] }> => {
  const result: Array<{ dropCategory: string; choices: Choice[] }> = [];

  if (dropCategories !== null && dropCategories !== undefined && dropCategories.length > 0) {
    dropCategories.forEach((category, index) => {
      const boxId = index.toString();
      const choicesInBox: Choice[] = boxes[boxId];
      result.push({ dropCategory: category, choices: choicesInBox });
    });
  }
  return result;
};

//  Restoring the sorting result from the store to the component
export const restoreSortingFromSavedData = (
  savedSorting: DropSortAnswer,
  originalChoices: Choice[],
  dropCategories?: string[],
): [Record<string, Choice[]>, Record<string, string>] => {
  const restoredBoxes: Record<string, Choice[]> = {};
  const restoredItemBoxMap: Record<string, string> = {};

  if (
      dropCategories !== null 
      && dropCategories !== undefined 
      && dropCategories.length > 0
    ) {
    dropCategories.forEach((category, index) => {
      const boxId = index.toString();

      const savedBox = savedSorting.sortedItems.find(
        (item) => item.dropCategory === category,
      );

      if ((savedBox?.choices) != null) {
        const choiceObjects = savedBox.choices.map((choice) =>
          originalChoices.find(
            (originalChoice) => originalChoice.id === choice.id,
          ) as Choice,
        );
        restoredBoxes[boxId] = choiceObjects;
        choiceObjects.forEach((choice) => {
          restoredItemBoxMap[choice.id] = boxId;
        });
      }
    });
  }
  return [restoredBoxes, restoredItemBoxMap];
};
