import styles from './TextLimitCounter.module.scss';

interface TextLimitCounterProps {
  count: number;
  limit: number;
}

const TextLimitCounter: React.FC<TextLimitCounterProps> = ({
  count,
  limit,
}) => {
  let colorClass = styles.default;
  if (count / limit >= 0.9) colorClass = styles.orange;
  if (count > limit) colorClass = styles.red;

  return (
    <div className={styles.container}>
      <p className={colorClass}>{`${count}/${limit}`}</p>
    </div>
  );
};

export default TextLimitCounter;
