import styles from './Note.module.scss';

interface NoteProps {
  prompt: string;
}

export default function Note({ prompt }: NoteProps): React.JSX.Element {
  const paragraphs = prompt.split('\n').map((text, index) => <p key={index}>{text}</p>);
    
  return (
    <div className={styles.container}>
      {paragraphs}
    </div>
  );
}
