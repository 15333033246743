import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { theme } from './config/muiTheme';
import { routes } from './utils/Routes';
import { AuthProvider } from './contexts/AuthContext';
import { msalConfig } from './config/msal-auth.config';
import { setAxiosInterceptors } from './services/api';

// Initialize the MSAL application object
const msalInstance = new PublicClientApplication(msalConfig);
const router = createBrowserRouter(routes);

function App(): React.JSX.Element {
  useEffect(() => {
    setAxiosInterceptors(msalInstance);
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
