import { makeAutoObservable } from 'mobx';

import QuestionnaireStore from './QuestionnaireStore';
import UserDashboardStore from './UserDashboardStore';
import UserAnswersStore from './UserAnswersStore';

class RootStore {
  userDashboardStore: UserDashboardStore;
  questionnaireStore: QuestionnaireStore;
  userAnswersStore: UserAnswersStore;

  constructor() {
    makeAutoObservable(this);
    this.userDashboardStore = new UserDashboardStore(this);
    this.userAnswersStore = new UserAnswersStore(this);
    this.questionnaireStore = new QuestionnaireStore(this);
  }
}

export default RootStore;
