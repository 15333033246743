import React, { useState, useEffect } from 'react';
import type { IconBaseProps } from 'react-icons';
import { inject, observer } from 'mobx-react';

import type RootStore from '../../../../../../stores/RootStore';
import ShortText from '../../QuestionTypes/ShortText/ShortText';
import AltTextInput from './AltTextInput/AltTextInput';
import { type MultiChoiceButtonsAnswer } from '../../../../../../types/userAnswer';
import { type Choice } from '../../../../../../types/index';
import styles from './MultiChoiceButtons.module.scss';

interface MultiChoiceButtonsProps {
  rootStore?: RootStore;
  questionnaireQuestionsId: number;
  choices?: Choice[];
  altTextPrompt?: string;
  altInput?: string; 
}

const MultiChoiceButtons: React.FC<MultiChoiceButtonsProps> = observer(
  ({ rootStore, questionnaireQuestionsId, choices = [], altTextPrompt }) => {
    const userAnswersStore = rootStore?.userAnswersStore;
    const userAnswer = userAnswersStore?.userPageAnswers[
      questionnaireQuestionsId
    ] as MultiChoiceButtonsAnswer;

    const [selected, setSelected] 
    = useState<Record<string, { iconName?: string; text?: string; isSelected: boolean }>>(
      userAnswer !== undefined ? userAnswer.selected  : {},
    );
    
    useEffect(() => {
      setSelected(userAnswer !== undefined ? userAnswer.selected : {});
    }, [userAnswer]);
    
    const [icons, setIcons] = useState<
      Record<string, React.ComponentType<IconBaseProps>>
    >({});

    useEffect(() => {
      void (async () => {
        const dynamicIcons = await Promise.all(
          choices.map(async (choice) => {
            if (choice.iconName != null) {
              try {
                // eslint-disable-next-line import/no-extraneous-dependencies
                const { [choice.iconName]: Icon } = (await import(
                  'react-icons/md'
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                )) as any;
                return { [choice.iconName]: Icon };
              } catch (error) {
                console.error(
                  `Failed to load icon "${choice.iconName}" as string`,
                  error,
                );
                return {};
              }
            }
            return {};
          }),
        );

        setIcons(dynamicIcons.reduce((a, b) => ({ ...a, ...b }), {}));
      })();
    }, [choices]);

    const handleChoiceClick = (choice: Choice): void => {
      setSelected(
        (prev: Record<string, { iconName?: string; text?: string; isSelected: boolean }>) => {
        const updated = { 
          ...prev, 
          [choice.id]: {
            iconName: choice.iconName,
            text: choice.text,
            isSelected: !prev[choice.id]?.isSelected,
          },
        };
        userAnswersStore?.setAnswerForQuestion(questionnaireQuestionsId, {
          selected: updated,
          type: 'multiple_choice_buttons',
        });
        return updated;
      });
    };

    return (
      <div className={styles.container}>
        <div className={styles.choices}>
          {choices?.map((choice) => (
            <button
            key={choice.id}
            className={selected[choice.id]?.isSelected ? styles.selected : ''}
            onClick={() => {
              handleChoiceClick(choice);
            }}
          >
            {choice.iconName != null &&
              icons[choice.iconName] !== undefined &&
              React.createElement(icons[choice.iconName])}
            {choice.text}
          </button>
          
          ))}
        </div>
        <div className={styles.altInput}>
          {altTextPrompt != null && altTextPrompt.length > 1  && (
            <>
              <ShortText prompt={altTextPrompt} />
              <AltTextInput questionnaireQuestionsId={questionnaireQuestionsId} />
            </>
          )}
        </div>
      </div>
    );
  },
);

export default inject('rootStore')(MultiChoiceButtons);
