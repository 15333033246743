/* eslint-disable max-len */
/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

const tenantId = '94957fb0-317d-4048-861c-5ac14663b7e8';

const msalConfig = {
  auth: {
    clientId: '752cc621-51b0-4b9e-887e-3738c5d24f09',
    // Full redirect URL, in form of http://localhost:3000
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    authority: `https://login.microsoftonline.com/${tenantId}/`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit:
* https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
const loginRequest = {
  scopes: ['User.Read', 'openid', 'profile'],
};

/**
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
const tokenRequest = {
  scopes: [],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export { msalConfig, loginRequest, tokenRequest };
