import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { useAuth } from '../../contexts/AuthContext';
import DescriptionPara from '../../components/DescriptionPara/DescriptionPara';
import DashboardCard from './components/DashboardCard/DashboardCard';
import InsightsBar from './components/InsightsBar/InsightsBar';
import styles from './Dashboard.module.scss';
import type RootStore from '../../stores/RootStore';
import LoadingComponent from '../../components/Loading/LoadingComponent';

interface DashboardProps {
  rootStore?: RootStore;
}

const Dashboard: React.FC<DashboardProps> = observer(({ rootStore }) => {
  const userDashboardStore = rootStore?.userDashboardStore;
  const { user } = useAuth();
  useEffect(() => {
    if (user != null && userDashboardStore?.totalPages === 0 ) {
      void userDashboardStore?.fetchDashboardData(user.email);
    }
  }, [user]);

  const { loading = false, error = null } = rootStore?.userDashboardStore ?? {};

  if (loading) return <LoadingComponent/>;
  if (error != null) return <div>{error}</div>;

  const userName: string =
    user !== null
      ? `${String(user.firstName)} ${String(user.lastName)}`
      : 'John Doe';

  return (
    <div className={styles.container}>
      <h1>Welcome, {userName}!</h1>
      <hr />
      <DescriptionPara
        content={userDashboardStore?.description}
      ></DescriptionPara>
      <InsightsBar />
      <section>
        {userDashboardStore?.cardData.map((data, index) => (
          <DashboardCard
            key={index}
            cardNo={index + 1}
            title={`${index + 1}. ${data.title}`}
            progress={data.progress}
          ></DashboardCard>
        ))}
      </section>
    </div>
  );
});

export default inject('rootStore')(Dashboard);
