import PageHeading from './QuestionTypes/PageHeading/PageHeading';
import Heading from './QuestionTypes/Heading/Heading';
import ShortText from './QuestionTypes/ShortText/ShortText';
import Note from './QuestionTypes/Note/Note';
import Statement from './QuestionTypes/Statement/Statement';
import MultiChoiceButtons from './AnswerTypes/MultiChoiceButtons/MultiChoiceButtons';
import TextInput from './AnswerTypes/TextInput/TextInput';
import ParaInput from './AnswerTypes/ParaInput/ParaInput';
import DegreeCertRows from './AnswerTypes/DegreeCertRows/DegreeCertRows';
import DropSort from './AnswerTypes/DropSort/DropSort';

import { type Question, type Answer } from '../../../../types/index';
import styles from './QuestionComponents.module.scss';

interface QuestionProps {
  questionAnswer: Question | Answer;
  page?: number;
  questionnaireQuestionsId: number;
}

const QuestionComponent: React.FC<QuestionProps> = ({
  questionAnswer,
  page = 1,
}) => {
  const renderQuestion = (): React.JSX.Element => {
    switch (questionAnswer?.type) {
      case 'page_heading':
        return (
          <PageHeading
            page={page}
            prompt={questionAnswer?.question_text.prompt}
          />
        );
      case 'heading':
        return <Heading prompt={questionAnswer?.question_text.prompt} />;
      case 'statement':
        return <Statement prompt={questionAnswer?.question_text.prompt} />;
      case 'note':
        return <Note prompt={questionAnswer?.question_text.prompt} />;
      case 'short_text':
        return (
          <ShortText
            prompt={(questionAnswer as Question).question_text.prompt}
          />
        );
      case 'long_text':
        return <>{questionAnswer?.question_text.prompt}</>;
      default:
        return <></>;
    }
  };

  // render based on answer.type
  const renderAnswer = (): React.JSX.Element => {
    switch (questionAnswer?.type) {
      case 'multiple_choice_buttons':
        return (
          <MultiChoiceButtons
            questionnaireQuestionsId={questionAnswer.id} 
            choices={questionAnswer.answerMetadata.choices}
            altTextPrompt={questionAnswer.answerMetadata.alternateTextPrompt}
            altInput={questionAnswer.answerMetadata.alternateTextInput}
          ></MultiChoiceButtons>
        );
      case 'multiple_choice_draggable':
        return (
          <DropSort
            questionnaireQuestionsId={questionAnswer.id}
            choices={questionAnswer.answerMetadata.choices}
            dropCategories={questionAnswer.answerMetadata.dropCategories}
            boxDirection={questionAnswer.answerMetadata.boxDirection}
          />
        );
      case 'short_text':
        return <></>;
      case 'para_text_input':
        return <ParaInput
        questionnaireQuestionsId={questionAnswer.id} rows={questionAnswer.answerMetadata.rows}/>;
      case 'text_input':
        return <TextInput questionnaireQuestionsId={questionAnswer.id}/>;
      case 'degreeCertRows':
        return <DegreeCertRows 
            questionnaireQuestionsId={questionAnswer.id}
            choices={questionAnswer.answerMetadata.choices}
          />;
      case 'other':
        return <></>;
      default:
        return <></>;
    }
  };

  const isQuestion = Boolean(questionAnswer?.isQuestion);

  return (
    <div className={styles.container}>
      { isQuestion && renderQuestion()}
      { !isQuestion && renderAnswer()}
    </div>
  );
};

export default QuestionComponent;
