import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { DndProvider } from 'react-dnd';

import type RootStore from '../../../../../../stores/RootStore';
import DroppableBox from './DroppableBox/DroppableBox';
import DraggableButton from './DraggableButton/DraggableButton';
import { useFlexDirection } from '../../../../../../utils/useFlexDirection';
import TouchDragLayer from './TouchDragLayer';
import {
  HTML5toTouch,
  MultiBackend,
} from '../../../../../../utils/DNDMultiBackend';

import { type Choice } from '../../../../../../types/index';
import { type DropSortAnswer } from '../../../../../../types/userAnswer';

import styles from './DropSort.module.scss';
import { convertBoxesToSortedItems, restoreSortingFromSavedData } from '../../../../../../utils';

interface DropSortProps {
  choices?: Choice[];
  dropCategories?: string[];
  boxDirection?: 'horizontal' | 'vertical';
  rootStore?: RootStore;
  questionnaireQuestionsId: number;
}

const DropSort: React.FC<DropSortProps> = observer(
  ({ 
    rootStore,
    choices = [],
    dropCategories,
    boxDirection = 'horizontal',
    questionnaireQuestionsId,
  }) => {
  const userAnswersStore = rootStore?.userAnswersStore;

  const [availableItems, setAvailableItems] = useState(choices);
  const [boxes, setBoxes] = useState<Record<string, Choice[]>>({});
  const [itemBoxMap, setItemBoxMap] = useState<Record<string, string>>({});
  const currentBoxDirection = useFlexDirection(boxDirection); // Use the custom hook

  // Flag to track if data loaded from UserAnswersStore
  const [isInitialized, setIsInitialized] = useState(false); 

  useEffect(() => {
    if (isInitialized) return;

    const savedSorting 
    = userAnswersStore?.userPageAnswers[questionnaireQuestionsId] as DropSortAnswer;
    if(savedSorting?.sortedItems != null && choices != null) {
      const [restoredBoxes, restoredItemBoxMap] 
      = restoreSortingFromSavedData(savedSorting, choices, dropCategories);
      const allBoxedItems = Object.values(restoredBoxes).flat().map((choice) => choice.id);
      const newAvailableItems = choices.filter((choice) => !allBoxedItems.includes(choice.id));
      if (JSON.stringify(boxes) !== JSON.stringify(restoredBoxes)) {
        setBoxes(restoredBoxes);
      }
      if (JSON.stringify(itemBoxMap) !== JSON.stringify(restoredItemBoxMap)) {
        setItemBoxMap(restoredItemBoxMap);
      }
      setAvailableItems(newAvailableItems);
      setIsInitialized(true); 
    }
  }, [userAnswersStore?.userPageAnswers[questionnaireQuestionsId], boxes, itemBoxMap]);
  
  useEffect(() => {
    setAvailableItems(choices);
  }, [choices]);

  // Save the sorting data to the store
  useEffect(() => {
    if (Object.keys(boxes).length === 0) {
      return;
    }
    const sortedItems = convertBoxesToSortedItems(boxes, dropCategories);
    userAnswersStore?.setAnswerForQuestion(questionnaireQuestionsId, {
      type: 'multiple_choice_draggable',
      sortedItems,
    });
  }, [boxes, itemBoxMap]);

  const handleDrop = (boxId: string, item: Choice): void => {
      // Prepare new states
    const newAvailableItems = availableItems.filter((i) => i.id !== item.id);
    const prevBoxId = itemBoxMap[item.id];
    const newBoxes = { ...boxes };
    if (prevBoxId != null) {
      newBoxes[prevBoxId] = newBoxes[prevBoxId].filter((i) => i.id !== item.id);
    }
    newBoxes[boxId] = [...(newBoxes[boxId] !== undefined ? newBoxes[boxId] : []), item];
    const newItemBoxMap = { ...itemBoxMap, [item.id]: boxId };

    // Update the states
    setAvailableItems(newAvailableItems);
    setBoxes(newBoxes);
    setItemBoxMap(newItemBoxMap);
  };
  return (
    <div className={styles.container}>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <TouchDragLayer />
        <div className={styles.draggableButtons}>
          {availableItems.map((choice: Choice, index: number) => (
            <DraggableButton
              key={choice.id}
              id={choice.id}
              text={choice.text}
            />
          ))}
        </div>
        <div
          className={styles.dropBoxes}
          style={{
            flexDirection:
              currentBoxDirection === 'vertical' ? 'row' : 'column',
          }}
        >
          {dropCategories?.map((category: string, index: number) => (
            <DroppableBox
              key={index}
              boxId={index.toString()}
              dropCategory={category}
              onDrop={handleDrop}
              items={boxes[index.toString()]}
            />
          ))}
        </div>
      </DndProvider>
    </div>
  );
});

export default inject('rootStore')(DropSort);
