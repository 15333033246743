import axios, { type AxiosResponse } from 'axios';
import { type PublicClientApplication } from '@azure/msal-browser';

import { tokenRequest } from '../config/msal-auth.config';
import {
  type GenericPostResponse,
  type DashboardData,
  type User,
  type Question,
  type Answer,
} from '../types';
import { API_ENDPOINTS } from '../utils/constants';
import { type UserPageAnswersPayload, type UserQuestionAnswerData } from '../types/userAnswer';
import { type ReportAnswerData } from '../types/insightsReport';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// TODO : handle refresh for expired/invalid tokens
export function setAxiosInterceptors(msalInstance: PublicClientApplication): void {
  // Request interceptor for API calls
  axiosInstance.interceptors.request.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (config: any) => {
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length > 0) {
        const accessTokenRequest = {
          ...tokenRequest,
          account: accounts[0],
        };

        try {
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${response.accessToken}`,
          };
        } catch (error) {
          console.error('Failed to acquire token silently', error);
          // Acquiring token using popup if silent call fails
          try {
            const response = await msalInstance.acquireTokenPopup(accessTokenRequest);
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${response.accessToken}`,
            };
          } catch (aquireTokenerror) {
            console.error('Failed to acquire token using popup', aquireTokenerror);
            throw aquireTokenerror;
          }
        }
      }

      return config;
    },
    async (error) => await Promise.reject(error),
  );
}

async function apiRequest<T>(request: Promise<AxiosResponse<T>>):
  Promise<AxiosResponse<T> | string> {
  try {
    const response = await request;
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const data = error.response?.data;
      const errorString = data != null ? `Error: ${String(data.message)}` : 'An error occurred';
      console.error('Error data:', data);
      console.error('Error status:', error.response?.status);
      console.error('Error headers:', error.response?.headers);
      return errorString;
    } else {
      console.error('An unknown error occurred:', error);
      return 'An unknown error occurred';
    }
  }
}

export async function registerUserIfNotExists(userDetails: User):
  Promise<AxiosResponse<GenericPostResponse> | string> {
  return await apiRequest(axiosInstance.post(API_ENDPOINTS.REGISTER_ON_LOGIN, userDetails));
}

export const loadDashboardData = async (email: string):
  Promise<AxiosResponse<DashboardData[]> | string> => {
  return await apiRequest(axiosInstance.post(API_ENDPOINTS.USER_DASHBOARD, { email }));
};

export const loadQuestionnaireData = async (questionnaireID: number, pageNo: number):
  Promise<AxiosResponse<Array<Question | Answer>> | string> => {
  const getURL = `${API_ENDPOINTS.QUESTIONNAIRES_PREFIX}/${questionnaireID}/page/${pageNo}`;
  return await apiRequest(axiosInstance.get(getURL));
};

export const loadUserPageAnswersData = async (userQuestionnaireId: number, pageNo: number):
  Promise<AxiosResponse<UserQuestionAnswerData[]> | string> => {
  const getURL = `${API_ENDPOINTS.USER}/${userQuestionnaireId}/answers/${pageNo}`;
  return await apiRequest(axiosInstance.get(getURL));
};

export async function saveUserPageAnswersData(payload: UserPageAnswersPayload):
  Promise<AxiosResponse<GenericPostResponse> | string> {
  return await apiRequest(axiosInstance.post(API_ENDPOINTS.USER_PAGE_ANSWERS, payload));
}

export const loadUserAnswersForReport = async (questionnaireID: string | undefined):
Promise<AxiosResponse<AxiosResponse<ReportAnswerData[]>> | string> => {
  const getURL = `${API_ENDPOINTS.QUESTIONNAIRES_PREFIX}/${questionnaireID as string}/answers`;
  return await apiRequest(axiosInstance.get(getURL));
};