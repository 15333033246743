import React from 'react';
import styles from './SoftwareTable.module.scss';
import { type MultiChoiceTableData } from '../../../../types/insightsReport';

interface SoftwareTableProps {
  data: Array<{
    selection: Record<string, MultiChoiceTableData | undefined>;
  }>;
}

const SoftwareTable: React.FC<SoftwareTableProps> = ({ data }) => {
  const allSoftware: string[] = [];

  // Gather all unique software names
  data.forEach((item) => {
    const selection = item.selection;
    Object.values(selection).forEach((dataItem) => {
      if (dataItem !== undefined && !allSoftware.includes(dataItem.text)) {
        allSoftware.push(dataItem.text);
      }
    });
  });

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Used before </th>
            <th>Would like to learn (more) </th>
          </tr>
        </thead>
        <tbody>
          {allSoftware.sort((a, b) => a.localeCompare(b)).map((software, index) => (
            <tr key={index}>
              <td>{software}</td>
              <td>
                {Object.values(data[0]?.selection).some(
                  (item: MultiChoiceTableData | undefined) => item !== undefined 
                    && item.text === software)
                  ? '✓'
                  : ''}
              </td>
              <td>
                {Object.values(data[1]?.selection).some(
                  (item: MultiChoiceTableData | undefined) => item !== undefined 
                    && item.text === software)
                  ? '✓'
                  : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SoftwareTable;
