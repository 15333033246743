import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  type AuthenticationResult,
} from '@azure/msal-browser';

import Button from '../../components/Button/Button';
import { ReactComponent as TopRightGraphic } from '../../assets/background/topRightGraphic.svg';
import { ReactComponent as BottomGraphic1 } from '../../assets/background/bottomWaveGraphic1.svg';
import { ReactComponent as BottomGraphic2 } from '../../assets/background/bottomWaveGraphic2.svg';
import styles from './Login.module.scss';

import { loginRequest } from '../../config/msal-auth.config';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { registerUserIfNotExists } from '../../services/api';
import { type User } from '../../types';

const Login = (): React.JSX.Element => {
  const navigate = useNavigate();
  const { setUser } = useAuth() as {
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
  };

  const { instance: msalInstance } = useMsal();
  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      console.info('User already logged in');
      navigate('/dashboard');
    }
  }, []);

  const handleMicrosoftLogin = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    msalInstance
      .acquireTokenPopup(loginRequest)
      .then(async (response: AuthenticationResult) => {
        console.debug('Microsoft login successful');
        const account = response.account;
        if (account?.name != null) {
          const firstName = account.name.split(' ')[0];
          const lastName = account.name.split(' ')[1];
          const userDetails = {
            firstName,
            lastName,
            email: account.username,
          };
          setUser(userDetails);
          try {
            await registerUserIfNotExists(userDetails);
            console.debug('User registered');
          } catch (error) {
            console.error('Error registering user', error);
          }
        } else {
          setUser(null);
        }
        navigate('/dashboard');
      })
      .catch((error: unknown) => {
        console.error(error);
      });
  };

  return (
    <div className={styles.container}>
      <TopRightGraphic className={styles.topRightGraphic} />
      <BottomGraphic1 className={styles.bottomGraphic1} />
      <BottomGraphic2 className={styles.bottomGraphic2} />
      <h1>MISSION TECHNIUS</h1>
      <form>
        <Button
          icon="microsoft"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            handleMicrosoftLogin(e);
          }}
        >
          Sign-in with Microsoft
        </Button>

        <h4>Other login options</h4>

        <div>Privacy Policy &nbsp;.&nbsp; Terms & Conditions</div>
      </form>
    </div>
  );
};

export default Login;
