import React from 'react';
// import React, { useEffect, useState } from 'react';
// import type { IconBaseProps } from 'react-icons';

import styles from './ChoiceDisplayButton.module.scss';
import { type MultiChoiceButtonData } from '../../../../types/insightsReport';

interface ChoiceDisplayButtonProps {
  choices?: Record<string, MultiChoiceButtonData>;
}

// type DynamicIcons = Record<string, React.ComponentType<IconBaseProps>>;

const ChoiceDisplayButton: React.FC<ChoiceDisplayButtonProps> = ({
  choices = {},
}) => {
  // const [icons, setIcons] = useState<
  //   Record<string, React.ComponentType<IconBaseProps>>
  // >({});

  // useEffect(() => {
  //   void (async () => {
  //     const dynamicIcons = await Promise.all(
  //       Object.values(choices).map(async (choice) => {
  //         if (choice.iconName != null && Boolean(choice?.isSelected)) {
  //           try {
  //             const { [choice.iconName]: Icon } 
  //             = (await import('react-icons/md')) as unknown as DynamicIcons;
  //             return { [choice.iconName]: Icon };
  //           } catch (error) {
  //             console.error(`Failed to load icon "${String(choice.iconName)}"`, error);
  //             return {};
  //           }
  //         }
  //         return {};
  //       }),
  //     );
  //     setIcons(dynamicIcons.reduce((a, b) => ({ ...a, ...b }), {}));
  //   })();
  // }, [choices]);

  return (
    <div className={styles.container}>
      <div className={styles.choices}>
        <ul>
          {Object.values(choices)
            .filter((choice) => Boolean(choice.isSelected))
            .map((choice, index) => (
              <li key={index}>
                {/* {choice.iconName != null &&
                  icons[choice.iconName] !== undefined &&
                React.createElement(icons[choice.iconName])} */}
                {choice.text}
              </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChoiceDisplayButton;
