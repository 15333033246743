import { makeAutoObservable, runInAction } from 'mobx';
import { type AxiosResponse } from 'axios';

import {
  type MultiChoiceButtonsAnswer,
  type UserAnswers,
  type UserQuestionAnswerData,
} from '../types/userAnswer';

import { type GenericPostResponse } from '../types';
import { loadUserPageAnswersData, saveUserPageAnswersData } from '../services/api';
import type RootStore from './RootStore';

class UserAnswersStore {
  initialPageAnswers: Record<number, UserAnswers> = {};
  userPageAnswers: Record<number, UserAnswers> = {};
  loading = false;
  error: string | null = null;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  clearUserPageAnswers(): void {
    console.log('clearing user page answers');
    runInAction(() => {
      this.userPageAnswers = {};
      this.initialPageAnswers = {};
    });
  }

  // Updates the answer as user inputs
  setAnswerForQuestion(questionnaireQuestionsId: number, answer: UserAnswers): void {
    runInAction(() => {
      // Start with the existing answer if it's available
      const existingAnswer = this.userPageAnswers[questionnaireQuestionsId];
      
      if (answer.type === 'alt_text_input') {

        const newAnswer: MultiChoiceButtonsAnswer = 
        existingAnswer?.type === 'multiple_choice_buttons'
        ? { ...existingAnswer  }
        : { type: 'multiple_choice_buttons', selected: {} };

        newAnswer.hasAltText = true;
        newAnswer.altText = answer.text;
  
        this.userPageAnswers[questionnaireQuestionsId] = newAnswer;
      }
      else if (existingAnswer?.type === 'multiple_choice_buttons') {
        const newAnswer: MultiChoiceButtonsAnswer = {
          // Merge existing values and new values
          ...existingAnswer,
          ...answer,
          type: 'multiple_choice_buttons', // Ensure the type remains consistent
        };
        this.userPageAnswers[questionnaireQuestionsId] = newAnswer;
      } else {
        // If no existing answer, or the existing answer is of a different type, overwrite it
        this.userPageAnswers[questionnaireQuestionsId] = answer;
      }
    });
  }

  // Loads the answer from the backend
  setAnswerForPageQuestions(userPageAnswers: UserQuestionAnswerData[]): void {
    runInAction(() => {
      userPageAnswers.forEach((userPageAnswer: UserQuestionAnswerData) => {
        if (userPageAnswer.userAnswer.type === 'multiple_choice_buttons') {
          this.userPageAnswers[userPageAnswer.questionnaireAnswersId] = userPageAnswer.userAnswer;
          (this.userPageAnswers[userPageAnswer.questionnaireAnswersId] as MultiChoiceButtonsAnswer)
            .hasAltText = true;
          (this.userPageAnswers[userPageAnswer.questionnaireAnswersId] as MultiChoiceButtonsAnswer)
            .altText = userPageAnswer.userAnswer.altText;
        }
        else {
          this.userPageAnswers[userPageAnswer.questionnaireAnswersId] = userPageAnswer.userAnswer;
        }
      });
    });
  }

  // Updates the initial answers after loading from the backend
  setInitialAnswersForPage(): void {
    this.initialPageAnswers = { ...this.userPageAnswers };
  }

  async fetchAnswers(userQuestionnaireId: number): Promise<void> {
    console.log('Fetching user page answers');
    this.clearUserPageAnswers();
    runInAction(() => {
      this.loading = true;
      this.error = null;
    });
    try {
      const pageNumber = this.rootStore.questionnaireStore.currentPage;
      if (Number.isNaN(pageNumber)) {
        throw new Error('Current page is not a number.');
      }
      const response: string | AxiosResponse<UserQuestionAnswerData[]>
        = await loadUserPageAnswersData(
          userQuestionnaireId,
            pageNumber,
          );
      runInAction(() => {
        if (response instanceof Object && 'data' in response) {
          this.setAnswerForPageQuestions(response.data);
          this.setInitialAnswersForPage();
        } else if (typeof response === 'string') {
          this.error = response;
        }
      });
    } catch (e) {
      runInAction(() => {
        console.debug('Error fetching user page answers');
        console.error(e);
        this.error = 'An error occurred while fetching user page answers.';
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async saveAnswers(pageNumber: number, oldUserPageAnswers: Record<number, UserAnswers>):
    Promise<void> {
    this.loading = true;
    this.error = null;

    try {
      const userQuestionnaireId = this.rootStore.questionnaireStore.userQuestionnaireId;
      const userAnswers 
      = Object.entries(oldUserPageAnswers).map(([ questionnaireAnswersId, userAnswer ]) => ({
        questionnaireAnswersId: Number(questionnaireAnswersId),
        userAnswer,
      }));

      const payload = {
        userQuestionnaireId,
        pageNumber,
        userAnswers,
      };

      const response: AxiosResponse<GenericPostResponse> | string =
        await saveUserPageAnswersData(payload);
      if (typeof response === 'string') {
        this.error = response;
      }
    } catch (e) {
      console.error('Error saving user answers', e);
      this.error = 'An error occurred while saving user answers.';
    } finally {
      this.loading = false;
      console.log('Save answers complete');
      this.clearUserPageAnswers();
    }
  }
}

export default UserAnswersStore;
