/* eslint-disable max-len */
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import type { NavigateFunction } from 'react-router-dom';
import type { AxiosResponse } from 'axios';

import { loadQuestionnaireData } from '../services/api';
import type { Question, PageData, PageLabel, Answer, ServerError } from '../types';
import type RootStore from './RootStore';
import { type UserAnswers } from '../types/userAnswer';

class QuestionnaireStore {
  totalPages!: number;
  questionnaireId!: number;
  userQuestionnaireId!: number;
  currentPage = 1;
  pageData: PageData = {
    questionAnswers: [],
    pageNumber: 1,
  };

  pageLabels: PageLabel[] | null = null;
  loading = false;
  error: string | null = null;
  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);

    // Preload  values from local storage.
    const totalPages = localStorage.getItem('totalPages');
    const questionnaireId = localStorage.getItem('questionnaireId');
    const userQuestionnaireId = localStorage.getItem('userQuestionnaireId');
    const pageLabels = localStorage.getItem('pageLabels');
    
    runInAction(() => {
      if (totalPages != null) this.totalPages = parseInt(totalPages);
      if (questionnaireId != null) this.questionnaireId = parseInt(questionnaireId);
      if (userQuestionnaireId != null) this.userQuestionnaireId = parseInt(userQuestionnaireId);
      if (pageLabels != null) this.pageLabels = JSON.parse(pageLabels);
    });

    // Save answers on page change
    reaction(
      () => [this.currentPage, toJS(this.rootStore.userAnswersStore.userPageAnswers)] as [number, Record<number, UserAnswers>],
      (
        newData: [ number, Record<number, UserAnswers> ],
        prevData: [ number, Record<number, UserAnswers> ],
      ) => {
        const [ newPageNum, newUserPageAnswers ] = newData;
        const [ oldPageNum, oldUserPageAnswers ] = prevData;

        const initialAnswers = this.rootStore.userAnswersStore.initialPageAnswers;

        const areAnswersEmpty = Object.keys(newUserPageAnswers).length === 0;
        const hasPageChanged = newPageNum !== oldPageNum;
        const hasAnswersChanged = JSON.stringify(initialAnswers) !== JSON.stringify(newUserPageAnswers);
        
        // Check if userPageAnswers is empty
        if (areAnswersEmpty) {
          console.info('userPageAnswers is empty, skipping save.');
          return;
        }

        if (hasPageChanged && hasAnswersChanged) {
          // Data has changed; initiate save
          this.rootStore.userAnswersStore.saveAnswers(oldPageNum, oldUserPageAnswers)
            .catch((err) => {
              console.error("Couldn't save answers", err);
            });
        }
      },
    );
  }

  initialize(totalPages: number, questionnaireId: number, userQuestionnaireId: number, pageLabels: PageLabel[]): void {
    runInAction(() => {
      this.totalPages = totalPages;
      this.questionnaireId = questionnaireId;
      this.userQuestionnaireId = userQuestionnaireId;
      this.pageLabels = pageLabels;
    });  
    localStorage.setItem('totalPages', totalPages.toString());
    localStorage.setItem('questionnaireId', questionnaireId.toString());
    localStorage.setItem('userQuestionnaireId', userQuestionnaireId.toString());
    localStorage.setItem('pageLabels', JSON.stringify(pageLabels));
  }

  async fetchPages(): Promise<void> {
    runInAction(() => {
      this.loading = true;
      this.error = null;
    });
    try {
      if (Number.isNaN(this.currentPage)) {
        throw new Error('Current page is not a number.');
      }
      // Current page and API has indexing from 1
      const response: string | AxiosResponse<Array<Question | Answer>, ServerError> = await loadQuestionnaireData(this.questionnaireId, this.currentPage);
      runInAction(() => {
        if (response instanceof Object && 'data' in response) {
          this.pageData.questionAnswers = response?.data;
          this.pageData.pageNumber = this.currentPage;
          void this.rootStore.userAnswersStore.fetchAnswers(this.userQuestionnaireId);
        } else if (typeof response === 'string') {
          this.error = response;
        }
      });
    } catch (e) {
      runInAction(() => {
        console.debug('Error fetching questionnaire pages');
        console.error(e);
        this.error = 'An error occurred while fetching questionnaire pages.';
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  setCurrentPage(page: number): void {
    runInAction(() => {
      this.currentPage = page;
    });
  }

  get isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  get isLastPage(): boolean {
    return this.currentPage === this.totalPages;
  }

  get navLabels(): { prev: string; current: string; next: string } {
    const currentPageIndex = this.currentPage - 1;
    const prevPageIndex = this.currentPage - 2;
    const nextPageIndex = this.currentPage;
    const prevPageNum = prevPageIndex + 1;
    const currentPageNum = currentPageIndex + 1;
    const nextPageNum = nextPageIndex + 1;

    const prevLabel = this.currentPage > 1
      ? ((this.pageLabels != null) ? `${prevPageNum}. ${String(this.pageLabels[prevPageIndex]?.title)}` : '')
      : 'Home';

    const currentLabel = ((this.pageLabels != null) ? `${currentPageNum}. ${String(this.pageLabels[currentPageIndex]?.title)}` : '');

    const nextLabel = ((this.pageLabels != null) && this.isLastPage)
      ? 'View Insights Report'
      : ((this.pageLabels != null) ? `${nextPageNum}. ${String(this.pageLabels[nextPageIndex]?.title)}` : '');
      return { prev: prevLabel, current: currentLabel, next: nextLabel };
  }

  incrementPage(navigate: NavigateFunction): void {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    if (!this.isLastPage) {
      runInAction(() => {
        this.currentPage += 1;
      });
      navigate(`/questionnaire/${this?.currentPage}`);
    } else {
      void this.rootStore?.userAnswersStore.saveAnswers(
        this.currentPage,
        this.rootStore?.userAnswersStore.userPageAnswers,
      );
      navigate('/insightsPreview');
    }
  }

  decrementPage(navigate: NavigateFunction): void {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    if (this.currentPage > 1) {
      runInAction(() => {
        this.currentPage -= 1;
      });
      navigate(`/questionnaire/${this.currentPage}`);
    } else {
      navigate('/dashboard');
    }
  }
}

export default QuestionnaireStore;
