import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';

import RootLayout from '../layouts/RootLayout';
import Dashboard from '../pages/Dashboard/Dashboard';
import InsightsReport from '../pages/InsightsReport/InsightsReport';
import Login from '../pages/Login/Login';
import My404Component from '../pages/My404Component/My404Component';
import Questionnaire from '../pages/Questionnaire/Questionnaire';

export const routes = [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <RootLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="login" replace />,
      },
      {
        path: 'home',
        element: <Navigate to="/dashboard" replace relative='path' />,
      },
      {
        path: 'dashboard',
        element: (
          <ErrorBoundary>
            <Dashboard />
          </ErrorBoundary>
        ),
      },
      {
        path: 'questionnaire/:pageNumber',
        element: (
          <ErrorBoundary>
            <Questionnaire />
          </ErrorBoundary>
        ),
      },
      {
        path: 'insightsPreview',
        element: (
          <ErrorBoundary>
            <InsightsReport preview />
          </ErrorBoundary>
        ),
      },
      {
        path: 'insightsReport',
        element: (
          <ErrorBoundary>
            <InsightsReport />
          </ErrorBoundary>
        ),
      },
      {
        path: 'login',
        element: (
          <ErrorBoundary>
            <Login />
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <My404Component />, // This will match any route not previously matched
  },
];
