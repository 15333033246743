import { inject, observer } from 'mobx-react';

import type RootStore from '../../../stores/RootStore';
import QuestionComponent from './QuestionComponents/QuestionComponents';
import NavHeaderComponent from '../NavHeaderComponent/NavHeaderComponent';
import NavFooterComponent from '../NavFooterComponent/NavFooterComponent';
import styles from './PageComponent.module.scss';

interface PageComponentProps {
  rootStore?: RootStore;
}

// Component for all the questions in a page
const PageComponent: React.FC<PageComponentProps> = observer(
  ({ rootStore }) => {
    const questionnairePageStore = rootStore?.questionnaireStore;
    const pageData = questionnairePageStore?.pageData;
    const questionAnswers = ((pageData?.questionAnswers) != null) ? pageData?.questionAnswers :[];
    return (
      <>
        <div className={styles.container}>
          <NavHeaderComponent isReport={false}/>
          {questionAnswers?.map((questionAnswer, index) => (
            <QuestionComponent
              questionnaireQuestionsId={questionAnswer.id}
              key={index}
              questionAnswer={questionAnswer}
              page={questionnairePageStore?.currentPage as number}
            />
          ))}
          <NavFooterComponent />
        </div>
      </>
    );
  },
);

export default inject('rootStore')(PageComponent);
