import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import TextLimitCounter from '../../TextLimitCounter/TextLimitCounter';
import type RootStore from '../../../../../../../stores/RootStore';
import { type MultiChoiceButtonsAnswer } from '../../../../../../../types/userAnswer';

import styles from './AltTextInput.module.scss';

interface AltTextInputProps {
  limit?: number;
  rootStore?: RootStore;
  questionnaireQuestionsId: number;
}

const AltTextInput: React.FC<AltTextInputProps> = observer(
  ({ rootStore, limit = 500, questionnaireQuestionsId }) => {
    const limitTolerance = 10;

    const userAnswersStore = rootStore?.userAnswersStore;
    const savedInput = userAnswersStore?.userPageAnswers[
      questionnaireQuestionsId
    ] as MultiChoiceButtonsAnswer;

    const [text, setText] = useState(savedInput?.altText ?? '');
    useEffect(() => { setText(savedInput?.altText ?? ''); }, [savedInput]);

    const handleTextChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      const inputText = event.target.value;
      if (inputText.length <= limit + limitTolerance) {
        userAnswersStore?.setAnswerForQuestion(questionnaireQuestionsId, {
          text: inputText,
          type: 'alt_text_input',
        });
        setText(inputText);
      }
    };

    return (
      <div className={styles.container}>
        <input
          type="text"
          className={styles.altInputElement}
          onChange={handleTextChange}
          value={text}
        />
        <TextLimitCounter count={text?.length ?? 0} limit={limit} />
      </div>
    );
  },
);

export default inject('rootStore')(AltTextInput);
