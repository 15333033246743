import { useRef } from 'react';
import { useDrop } from 'react-dnd';

import { type Choice } from '../../../../../../../types/index';
import ShortText from '../../../QuestionTypes/ShortText/ShortText';
import DraggableButton from '../DraggableButton/DraggableButton';

import styles from './DroppableBox.module.scss';

interface DroppableBoxProps {
  boxId: string;
  dropCategory: string;
  onDrop: (boxId: string, item: Choice) => void;
  items?: Choice[];
}
const DroppableBox = ({
  boxId,
  dropCategory,
  onDrop,
  items = [],
}: DroppableBoxProps): React.JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'draggableButton',
    drop: (item, monitor) => {
      if (monitor.canDrop()) {
        onDrop(boxId, item as Choice);
      }
    },
  });

  drop(ref);

  return (
    <div className={styles.container}>
      <ShortText prompt={dropCategory}></ShortText>
      <section ref={ref} className={styles.box}>
        {items.map((item: Choice, index: number) => (
          <DraggableButton key={item.id} id={item.id} text={item.text} />
        ))}
      </section>
    </div>
  );
};

export default DroppableBox;
