import React, { useState } from 'react';

import styles from './TextCellInput.module.scss';
import { type DegreeCertRow } from '../../../../../../types/userAnswer';

interface TextCellInputProps {
  label?: string;
  width?: string;
  cellNum: string;
  rowNum: number;
  updateRow: (rowIndex: number, key: keyof DegreeCertRow, value: string | Date) => void;
  initialText?: string | null;
}

export default function TextCellInput({
  label,
  width,
  rowNum,
  cellNum,
  updateRow,
  initialText = "",
}: TextCellInputProps): React.JSX.Element {
  
  // Initialize local state for text
  const [text, setText] = useState(initialText);

  // Function to handle text changes
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputText = event.target.value;
    
    // Update both local state and the parent component's state
    setText(inputText);
    const keyValue = `cell${cellNum}Text` as keyof DegreeCertRow;
    updateRow(rowNum, keyValue, inputText);
  };

  return (
    <div className={styles.container} style={{ flexBasis: width }}>
      {label !== null && <label className={styles.label}>{label}</label>}
      <input
        type="text"
        className={styles.altInputElement}
        value={text ?? ''}
        onChange={handleTextChange}
      />
    </div>
  );
}
