import loadingGif from '../../assets/icons/loading-spinner.gif';
import styles from './LoadingComponent.module.scss';

const LoadingComponent: React.FC = () => (
  <div className={styles.container}>
    <img src={loadingGif} alt="Loading..." />
  </div>
);

export default LoadingComponent;
