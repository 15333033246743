/* eslint-disable max-len */
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import PageProgress from './PageProgressComponent/PageProgress';
import NavCrumbs from './BreadCrumbsComponent/NavCrumbs';
import styles from './NavHeaderComponent.module.scss';

import { ReactComponent as ArrowLeftDouble } from '../../../assets/icons/components/left-arrow-double.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/components/Header/icon_home.svg';

interface NavHeaderComponentProps {
  isReport?: boolean;
}

const NavHeaderComponent: React.FC<NavHeaderComponentProps> = ({ isReport = false}) => {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.home}>
          <NavLink to="/dashboard" className={styles.navLink}>
            <ArrowLeftDouble></ArrowLeftDouble>
            <HomeIcon className={styles.iconLink} />
            <span>Home</span>
          </NavLink>
        </div>
        {!isReport && <NavCrumbs></NavCrumbs>}
        {!isReport && <PageProgress></PageProgress>}
      </div>
    </>
  );
};

export default NavHeaderComponent;
