import { makeAutoObservable, runInAction } from 'mobx';
import { type AxiosResponse } from 'axios';

import { loadDashboardData } from '../services/api';
import { type DashboardData, type CardItem, type User, type ServerError } from '../types';
import type RootStore from './RootStore';

class UserDashboardStore {
  user: User | null = null;
  cardData: CardItem[] = [];
  description = '';
  totalPages = 0;
  questionnaireId = 0;
  userQuestionnaireId = 0;
  userId = 0;
  insightsGeneratedAt = '';
  loading = false;
  error: string | null = null;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
    const userQuestionnaireId = localStorage.getItem('userQuestionnaireId');
    const userId = localStorage.getItem('userId');
    if (userQuestionnaireId != null) this.userQuestionnaireId = parseInt(userQuestionnaireId);
    if (userId != null) this.userId = parseInt(userId);
  }

  setUser(user: User): void {
    this.user = user;
  }

  async fetchDashboardData(email: string): Promise<void> {
    runInAction(() => {
      this.loading = true;
      this.error = null;
    });

    try {
      const response: string | AxiosResponse<DashboardData[], ServerError> =
        await loadDashboardData(email);
      runInAction(() => {
        if (response instanceof Object && 'data' in response) {
          this.cardData = response.data[0].questionnaire.pages;
          this.description = response.data[0].questionnaire.description;
          this.totalPages = response.data[0].questionnaire.totalPages;
          this.questionnaireId = response.data[0].questionnaire.id;
          this.userQuestionnaireId = response.data[0].id;
          this.userId = response.data[0].userId;
          localStorage.setItem('userQuestionnaireId', this.userQuestionnaireId.toString());
          localStorage.setItem('userId', this.userId.toString());

          this.insightsGeneratedAt =
            response.data[0].insightsGeneratedAt !== null ?
             response.data[0].insightsGeneratedAt 
             : '';

          const pageLabels = this.cardData.map(page => ({
            pageNumber: page.pageNumber,
            title: page.title,
          }));

          // Initialize the QuestionnaireStore with values from UserDashboardStore
          this.rootStore.questionnaireStore.initialize(
            this.totalPages,
            this.questionnaireId,
            this.userQuestionnaireId,
            pageLabels,
          );
        } else if (typeof response === 'string') {
          this.error = response;
        }
      });
    } catch (e) {
      runInAction(() => {
        console.debug('Error fetching dashboard data');
        console.error(e);
        this.error = 'An error occurred while fetching dashboard data.';
      });
    } finally {
      runInAction(() => {
        console.debug('Fetching dashboard completed');
        this.loading = false;
      });

    }
  }
}

export default UserDashboardStore;
