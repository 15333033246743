import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useState, useEffect } from 'react';

import styles from './InsightsReport.module.scss';
// import { MdFileDownload } from 'react-icons/md';
import { AiOutlinePrinter } from 'react-icons/ai';
import NavFooterComponent from '../Questionnaire/NavFooterComponent/NavFooterComponent';
import NavHeaderComponent from '../Questionnaire/NavHeaderComponent/NavHeaderComponent';

import type RootStore from '../../stores/RootStore';
import { useAuth } from '../../contexts/AuthContext';
import { reportPreviewData } from '../../utils/constants';
import { loadUserAnswersForReport } from '../../services/api';
import {
  type MultiChoiceTableData,
  type ReportAnswerData,
} from '../../types/insightsReport';

// eslint-disable-next-line max-len
import PageHeading from '../Questionnaire/PageComponent/QuestionComponents/QuestionTypes/PageHeading/PageHeading';
import Note from '../Questionnaire/PageComponent/QuestionComponents/QuestionTypes/Note/Note';
// eslint-disable-next-line max-len
import Heading from '../Questionnaire/PageComponent/QuestionComponents/QuestionTypes/Heading/Heading';
// eslint-disable-next-line max-len
import ShortText from '../Questionnaire/PageComponent/QuestionComponents/QuestionTypes/ShortText/ShortText';
// eslint-disable-next-line max-len
import Statement from '../Questionnaire/PageComponent/QuestionComponents/QuestionTypes/Statement/Statement';
// eslint-disable-next-line max-len
import ReportDisplayColumnLayout from './components/ReportDisplayColumnLayout/ReportDisplayColumnLayout';
import ChoiceDisplayButton from './components/ChoiceDisplayButton/ChoiceDisplayButton';
import SoftwareTable from './components/SoftwareTable/SoftwareTable';

import TechniusLogo from '../../assets/icons/components/Header/TechniusLogo.png';

interface InsightsReportProps {
  preview?: boolean;
  rootStore?: RootStore;
}

const InsightsPreview: React.FC<InsightsReportProps> = observer(
  ({ rootStore, preview }) => {
    runInAction(() => {
      rootStore?.questionnaireStore.setCurrentPage(9);
    });
    const { user } = useAuth();
    const userName: string =
      user !== null
        ? `${String(user.firstName)} ${String(user.lastName)}`
        : 'John Doe';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [reportAnswerData, setReportAnswerData] = useState<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ReportAnswerData[] | any
    >(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fetchData = async (): Promise<any> => {
        setLoading(true);
        try {
          const stringQuestionnaireId =
            rootStore?.questionnaireStore?.userQuestionnaireId.toString();
          const response = await loadUserAnswersForReport(
            stringQuestionnaireId,
          );

          if (typeof response === 'string') {
            setError(response);
          } else {
            setReportAnswerData(response.data);
          }
        } catch (err) {
          setError('An error occurred while fetching data');
        } finally {
          setLoading(false);
        }
      };
      void fetchData();
    }, []);

    const convertChoicesToString = (selected: string[]): string => {
      const selectedItems = Object.values(selected)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((item: any) => item?.isSelected)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((item: any) => item?.text)
        .join(', ');
      return selectedItems;
    };

    const findAnswer = (
      page: number,
      sequence: number,
      convertMultiSelect = false,
      addAltText = false,
    ): ReportAnswerData => {
      const answer = reportAnswerData?.find(
        (item: ReportAnswerData) =>
          item.pageNumber === page && item.sequence === sequence,
      );

      if (answer !== undefined) {
        const altText: string = answer?.userAnswer?.altText as string;

        if (convertMultiSelect && Boolean(answer?.userAnswer?.selected)) {
          answer.userAnswer.text = convertChoicesToString(
            answer?.userAnswer?.selected,
          );
          if (addAltText && altText?.length > 0) {
            answer.userAnswer.text =
              (answer.userAnswer.text as string) + '. ' + altText;
          }
        }
      }
      return answer ?? {};
    };

    const defaultSelection: Record<string, MultiChoiceTableData | undefined> =
      {};
    const softwareTableSelection1: Record<string, MultiChoiceTableData | undefined> =
      findAnswer(4, 8)?.userAnswer?.selected ?? defaultSelection;
    const softwareTableSelection2: Record<string, MultiChoiceTableData | undefined> =
      findAnswer(4, 11)?.userAnswer?.selected ?? defaultSelection;

    const printQualifications = (): Array<JSX.Element | null> | null => {
      const qualifications = findAnswer(6, 13)?.userAnswer?.rows;
      if (qualifications == null) return null;
    
      const renderElements = qualifications.map((qualification, index) => {
        if (
          qualification?.dateCell == null ||
          qualification?.id == null ||
          qualification?.cell1Text == null ||
          qualification?.cell2Text == null
        ) {
          return null;
        }
    
        const month = (new Date(qualification.dateCell).getUTCMonth() + 1)
          .toString()
          .padStart(2, '0');
        const year = new Date(qualification.dateCell).getUTCFullYear();
    
        return (
          <li key={qualification.id}>
            {`${month}/${year}`} - {qualification.cell1Text} - {qualification.cell2Text}
          </li>
        );
      });
      return renderElements.length > 0 ? 
      renderElements : [<li key="no-qualifications">No Qualifications Available</li>];
    };      

    const getThingsDone = (): Array<JSX.Element | null> | null => {
      const selectedSkills = findAnswer(4, 2)?.userAnswer?.selected;
    
      if (selectedSkills == null) {
        return null;
      }
    
      return Object.values(selectedSkills).map((choice, index) => {
        if (choice?.isSelected) {
          return <li key={index}>{choice.text}</li>;
        }
        return null;
      });
    };
    const getThingsToLearn = (): Array<JSX.Element | null> | null => {
      const selectedSkills = findAnswer(4, 6)?.userAnswer?.selected;
      
      if (selectedSkills == null) {
        return null;
      }
    
      return Object.values(selectedSkills).map((choice, index) => {
        if (choice?.isSelected) {
          return <li key={index}>{choice.text}</li>;
        }
        return null;
      });
    };
    
    // const printTechChoices = (): Array<JSX.Element | null> | null => {
    //   const selectedTechChoices = findAnswer(1, 4)?.userAnswer?.selected;
    
    //   if (selectedTechChoices == null) {
    //     return null;
    //   }
    
    //   return Object.values(selectedTechChoices).map((choice, index) => {
    //     if (choice?.isSelected) {
    //       return <li key={index} className={styles.techSkills}>{choice.text}</li>;
    //     }
    //     return null;
    //   });
    // };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const printTextFromChoice = (choices: Record<string, any> | undefined): string => {
      if (choices === undefined) {
        return '';
      }
      return Object.values(choices)
      .map((choice) => choice.text)
      .join(", ");
    };
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const printTextFromDragSort = (sortedItems: any[] | undefined, itemIndex: number): string => {
      if (sortedItems === undefined) {
          return '';
      }
      const item = sortedItems[itemIndex];
      if (item === undefined) {
          return '';
      }
      
      const choices = item.choices;
      
      if (choices === undefined) {
          return '';
      }
      
      if (Array.isArray(choices)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const choiceText = choices.map((choice: any) => choice.text).join(", ");
          return choiceText;
      } else {
          console.error('choices is not an array:', choices);
          return '';
      }
    };  

    // const downloadReport = (): void => {
    //   console.log('download report');
    // };

    const printReport = (): void => {
      window.print();
    };

    return (
      <div className={styles.container}>
        {loading && <p>Loading...</p>}
        {error !== null && <p>Error: {error}</p>}
        <NavHeaderComponent isReport={true} />
        <section className={styles.content}>
          <article className={styles.head}>
            <div className={styles.onlyPrintView}>
            <img className={styles.logo} 
              src={TechniusLogo} alt="Technius Logo" /> &nbsp;&nbsp;&nbsp;
              <PageHeading page={9} prompt={'Mission Technius'} isReport={true} />
            </div>
            <PageHeading page={9} prompt={'Insights Report'} isReport={true} />
            <h3 className={styles.userName}>{userName}</h3>
            <div className={styles.outputButtons}>
              {/* <button className={styles.report} onClick={downloadReport}>
                <MdFileDownload className={styles.topBarIcon}>
                </MdFileDownload> Download</button> */}
              <button className={styles.report} onClick={printReport}>
                <AiOutlinePrinter className={styles.topBarIcon}>
                </AiOutlinePrinter> Print</button>
            </div>
          </article>
          <hr />
          <article className={styles.noPrint}>
            <Note prompt={reportPreviewData.note}></Note>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="About me"
              navDestination="/questionnaire/3"
            />
            <Statement topMargin={"0"} prompt={findAnswer(3, 8)?.userAnswer?.text}></Statement>
            <ShortText prompt={reportPreviewData.friends}></ShortText>
            <Statement topMargin={"-0.5"} prompt={findAnswer(3, 5)?.userAnswer?.text}></Statement>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Key Moments"
              navDestination="/questionnaire/2"
            />
            <ReportDisplayColumnLayout customText="Study or Work:">
              <ShortText
                topMargin={'0'}
                prompt={'A challenge I overcame, was:'}
              />
              <Statement
                prompt={findAnswer(2, 5)?.userAnswer?.text}
              ></Statement>
              <br />
              <ShortText
                prompt={'Something or someone that had an impact on me:'}
              />
              <Statement
                prompt={findAnswer(2, 6)?.userAnswer?.text}
              ></Statement>
              <br />
              <ShortText prompt={'Something I achieved:'} />
              <Statement
                prompt={findAnswer(2, 7)?.userAnswer?.text}
              ></Statement>
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Personal life:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(2, 9)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Interests"
              navDestination="/questionnaire/1"
            />
            <ReportDisplayColumnLayout customText="Personal interests:">
              <ChoiceDisplayButton
                choices={findAnswer(1, 3)?.userAnswer?.selected}
              />
              <Statement
                topMargin={'0.5'}
                prompt={findAnswer(1, 3)?.userAnswer?.altText}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Technology:" topMargin={'1'}>
              <ChoiceDisplayButton
                choices={findAnswer(1, 4)?.userAnswer?.selected}
              />
              <Statement
                topMargin={'0.5'}
                prompt={findAnswer(1, 4)?.userAnswer?.altText}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Causes:" topMargin={'1'}>
              <ChoiceDisplayButton
                choices={findAnswer(1, 6)?.userAnswer?.selected}
              />
              <Statement
                topMargin={'0'}
                prompt={findAnswer(1, 6)?.userAnswer?.altText}
              />
            </ReportDisplayColumnLayout>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="My ideal job"
              navDestination="/questionnaire/5"
            />
            <ReportDisplayColumnLayout customText="Most important:">
              <Statement topMargin={'0'}
                prompt={
                  printTextFromDragSort(findAnswer(5, 3)?.userAnswer?.sortedItems, 0)
                }/>
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Neutral:">
            <Statement topMargin={'0'}
                prompt={
                  printTextFromDragSort(findAnswer(5, 3)?.userAnswer?.sortedItems, 1)
                }/>
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Least important:">
            <Statement topMargin={'0'}
                prompt={
                  printTextFromDragSort(findAnswer(5, 3)?.userAnswer?.sortedItems, 2)
                }/>
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Work should involve:">
              <Statement topMargin={'0'}
                // eslint-disable-next-line max-len
                prompt={`${printTextFromChoice(findAnswer(5, 4)?.userAnswer?.selected)}, ${findAnswer(5, 4)?.userAnswer?.altText as string}`}
              />
            </ReportDisplayColumnLayout>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Goals and Vision"
              navDestination="/questionnaire/8"
            />
            <ReportDisplayColumnLayout customText="Study I’m considering:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(8, 3)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="If anything was possible:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(8, 6)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Jobs I’m interested in:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(8, 4)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Jobs that aren’t appealing:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(8, 5)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Potential Obstacles"
              navDestination="/questionnaire/8"
            />
            <ReportDisplayColumnLayout customText="Obstacles:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(8, 8)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Concerns:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(8, 9)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Challenges:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(8, 10)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Time and Resources"
              navDestination="/questionnaire/7"
            />
            <Statement
              topMargin={'0'}
              prompt={findAnswer(7, 4)?.userAnswer?.text}
            />
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Transferable Skills"
              navDestination="/questionnaire/4"
            />
            <ul className={styles.skills}>
              {getThingsDone()}
            </ul>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Skills I’d like to learn or get better at"
              navDestination="/questionnaire/4"
            />
            <ul className={styles.skills}>
              {getThingsToLearn()}
            </ul>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Software"
              navDestination="/questionnaire/4"
            />
            <SoftwareTable
              data={[
                { selection: softwareTableSelection1 }, { selection: softwareTableSelection2 }]}
            />
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Experience"
              navDestination="/questionnaire/6"
            />
            <ReportDisplayColumnLayout customText="Jobs and Volunteering:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(6, 3)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout topMargin={'1'} customText="Self-Study:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(6, 4)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Made or built:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(6, 5, true, true)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout 
            topMargin={'1'} customText="Useful-for-tech studies">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(6, 7, true, false)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Last year of school:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(6, 8)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
            <ReportDisplayColumnLayout customText="Favourite subjects:">
              <Statement
                topMargin={'0'}
                prompt={findAnswer(6, 9)?.userAnswer?.text}
              />
            </ReportDisplayColumnLayout>
          </article>
          <article>
            <Heading
              isReport={true}
              prompt="Qualifications"
              navDestination="/questionnaire/6"
            />
            <ul className={styles.skills}>{printQualifications()}</ul>
          </article>
        </section>
        <NavFooterComponent />
      </div>
    );
  },
);

export default inject('rootStore')(InsightsPreview);
