import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import styles from './NextPageComponent.module.scss';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowRight } from '../../../../assets/icons/components/right-arrow.svg';
import type RootStore from '../../../../stores/RootStore';

interface NextPageComponentProps {
  rootStore?: RootStore;
}

const NextPageComponent: React.FC<NextPageComponentProps> = observer(
  ({ rootStore }) => {
    const navigate = useNavigate();
    const questionnaireStore = rootStore?.questionnaireStore;
    const nextNavLabel = questionnaireStore?.navLabels.next;
    const currentPage = questionnaireStore?.currentPage;
    return (
      <>
        {currentPage !== 9 && (
          <>
            <div className={styles.container}>
              <div
                className={styles.navLink}
                onClick={() => questionnaireStore?.incrementPage(navigate)}
              >
                <span>{nextNavLabel}</span>
                <ArrowRight />
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

export default inject('rootStore')(NextPageComponent);
