import styles from './ShortText.module.scss';

interface ShortTextProps {
  prompt?: string | string[];
  topMargin?: string | null;
  maxWidth?: string | null;
}

export default function ShortText({
  prompt = '',
  topMargin = null,
  maxWidth = null,
}: ShortTextProps): React.JSX.Element {
  const topMarginStyle =
    topMargin !== null ? { marginTop: `${topMargin ?? '0'}em` } : {};
  const maxWidthStyle = 
    maxWidth !== null ? { maxWidth: `${maxWidth}` } : {};

  const containerStyle = { ...topMarginStyle, ...maxWidthStyle };

  return (
    <div className={styles.container} style={containerStyle}>
      <h4>{prompt}</h4>
    </div>
  );
}
