/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { type DegreeCertRow } from '../../../../../../types/userAnswer';
import { type Choice } from '../../../../../../types';
import type RootStore from '../../../../../../stores/RootStore';

import TextCellInput from '../TextCellInput/TextCellInput';
import YearMonthDateInput from '../YearMonthDateInput/YearMonthDateInput';
import Button from '../../../../../../components/Button/Button';
import styles from './DegreeCertRows.module.scss';
import { runInAction } from 'mobx';

interface DegreeCertRowsProps {
  rootStore?: RootStore;
  questionnaireQuestionsId: number;
  choices?: Choice[];
}

const DegreeCertRows: React.FC<DegreeCertRowsProps> = observer(
  ({ rootStore, questionnaireQuestionsId, choices }) => {
    const userAnswersStore = rootStore?.userAnswersStore;
    const answer = userAnswersStore?.userPageAnswers[questionnaireQuestionsId];

    // Type guard to check if the answer is of the expected type
    const isDegreeCertRowsAnswer = (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      answer: any,
    ): answer is { type: 'degreeCertRows'; rows: DegreeCertRow[] } => {
      return answer?.type === 'degreeCertRows' && Array.isArray(answer?.rows);
    };

    const initialRows = isDegreeCertRowsAnswer(answer) ? answer.rows : [];

    const [rows, setRows] = useState<DegreeCertRow[]>(initialRows);

    // Sync state with MobX store whenever it changes
    useEffect(() => {
      if ((userAnswersStore?.loading) === true) return;
      const updatedAnswer =
        userAnswersStore?.userPageAnswers[questionnaireQuestionsId];
      if (isDegreeCertRowsAnswer(updatedAnswer) 
        && JSON.stringify(updatedAnswer.rows) !== JSON.stringify(rows)) {
        runInAction(() => {
          setRows(updatedAnswer.rows);
        });
      }
    }, [userAnswersStore?.userPageAnswers[questionnaireQuestionsId]]);

    useEffect(() => {
      userAnswersStore?.setAnswerForQuestion(questionnaireQuestionsId, {
        type: 'degreeCertRows',
        rows,
      });
    }, [rows, questionnaireQuestionsId, userAnswersStore]);

    const addRow = (): void => {
      const newRow: DegreeCertRow = {
        id: `${new Date().getTime()}-${Math.random().toFixed(2)}`,
        cell1Text: '',
        cell2Text: '',
        dateCell: null,
      };
      runInAction(() => {
        setRows([...rows, newRow]);
      });
    };

    const removeRow = (rowIndex: number): void => {
      const newRows = [...rows];
      newRows.splice(rowIndex, 1);
      runInAction(() => {
        setRows(newRows);
      });
    };

    const updateRow = (
      rowIndex: number,
      key: keyof DegreeCertRow,
      value: string | Date | null,
    ): void => {
      // Check if the value is not null, not undefined, and not an empty string
      if (value !== null && value !== undefined && value !== '') {
        runInAction(() => {
          setRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[rowIndex][key] = value as any;
            return updatedRows;
          });
        });
      }
    };
    
    return (
      <div className={styles.outerContainer}>
        {rows?.map((_row, rowIndex) => (
          <div key={_row.id} className={styles.container}>
            <YearMonthDateInput
              updateRow={updateRow}
              rowNum={rowIndex}
              label={choices?.[0]?.text ?? ''}
              initialDate={rows[rowIndex]?.dateCell}
            />
            <TextCellInput
              updateRow={updateRow}
              cellNum={'1'}
              rowNum={rowIndex}
              label={choices?.[1]?.text ?? ''}
              initialText={rows[rowIndex]?.cell1Text} 
            />
            <TextCellInput
              updateRow={updateRow}
              cellNum={'2'}
              rowNum={rowIndex}
              label={choices?.[2]?.text ?? ''}
              initialText={rows[rowIndex]?.cell2Text} 
            />
            {rows.length >= 2 && (
              <button
                className={styles.close}
                onClick={() => {
                  removeRow(rowIndex);
                }}
              >
                X
              </button>
            )}
          </div>
        ))}
        <Button onClick={addRow}>{choices?.[3]?.text ?? ''}</Button>
      </div>
    );
  },
);

export default inject('rootStore')(DegreeCertRows);
