import styles from './PageHeading.module.scss';

interface PageHeadingProps {
  prompt: string | string[];
  page?: number;
  isReport?: boolean;
}

export default function PageHeading({
  prompt,
  page = 1,
  isReport = false,
}: PageHeadingProps): React.JSX.Element {
  const reportStyle = isReport ? { marginBottom: 0 } : {};
  return (
    <div className={styles.container}>
      <h3>{isReport ? prompt : `${page}. ${prompt as string}`}</h3>
      {!isReport && <hr style={reportStyle} />}
    </div>
  );
}
