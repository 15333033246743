import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import type RootStore from '../../../../stores/RootStore';

import styles from './NavCrumbs.module.scss';

// eslint-disable-next-line max-len
import { ReactComponent as ArrowRight } from '../../../../assets/icons/components/right-arrow.svg';

interface NavCrumbsProps {
  rootStore?: RootStore;
}

const NavCrumbs: React.FC<NavCrumbsProps> = observer(({ rootStore }) => {
  const questionnairePageStore = rootStore?.questionnaireStore;
  const pageNo = questionnairePageStore?.currentPage ?? 1;
  const navLabels = questionnairePageStore?.navLabels ?? { prev: '', current: '', next: '' };
  const isFirstPage = questionnairePageStore?.isFirstPage ?? false;
  const isLastPage = questionnairePageStore?.isLastPage ?? false;
  
  const saveUserAnswers = (): void => {
    if (rootStore != null) {
      void rootStore.userAnswersStore.saveAnswers(
        rootStore.questionnaireStore.currentPage,
        rootStore.userAnswersStore.userPageAnswers,
      );
    }
  };  

  return (
    <div className={styles.container}>
      {pageNo >= 3 && (
        <div className={styles.hideOnMobile}>
          ... <ArrowRight />
        </div>
      )}
      {!isFirstPage && (
        <div className={styles.prev}>
          <NavLink
            className={styles.navLink}
            relative="path"
            to={`/questionnaire/${pageNo - 1}`}
          >
            {navLabels.prev}
            <ArrowRight />
          </NavLink>
        </div>
      )}
      <div className={styles.current}>{navLabels.current} &nbsp;</div>
      <ArrowRight />
      <span className={styles.hideOnDesktop}>...</span>
      {isLastPage
        ? (
          <div className={styles.next}>
            <NavLink
              className={styles.navLink}
              relative="path"
              onClick={saveUserAnswers}
              to="/insightsPreview"
            >
              View Insights Report
            </NavLink>
          </div>
          )
        : (
        <div className={styles.next}>
          <NavLink
            className={styles.navLink}
            relative="path"
            to={`/questionnaire/${pageNo + 1}`}
          >
            {navLabels.next}
            <ArrowRight />
          </NavLink>
          ...
        </div>
          )}
    </div>
  );
});

export default inject('rootStore')(NavCrumbs);
