import { useDragLayer } from 'react-dnd';

// eslint-disable-next-line max-len
import styles from './DraggableButton/DraggableButton.module.scss';
import touchStyles from './TouchDragLayer.module.scss';

function TouchDragLayer(): React.JSX.Element | null {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  );

  // Only show the custom drag layer for the TouchBackend
  if (
    !isDragging ||
    item == null ||
    initialOffset == null ||
    currentOffset == null
  ) {
    return null;
  }

  const { x, y } = currentOffset;

  const style = {
    transform: `translate(${x}px, ${y}px)`,
    position: 'fixed' as const,
    pointerEvents: 'none' as const,
    zIndex: 100,
    left: 0,
    top: 0,
  };

  return (
    <div className={`${styles.container} ${touchStyles.container}`} style={style}>
      <button>{item.text}</button>
    </div>
  );
}

export default TouchDragLayer;
