import { useEffect, useState } from 'react';

export function useFlexDirection(
  incomingDirection: 'horizontal' | 'vertical',
): 'horizontal' | 'vertical' {
  const [currentDirection, setCurrentDirection] = useState<
  'horizontal' | 'vertical'
  >(incomingDirection);

  const defaultMobileDirection = 'horizontal';

  useEffect(() => {
    const handleResize = (): void => {
      const newDirection =
        window.innerWidth <= 767 ? defaultMobileDirection : incomingDirection;
      setCurrentDirection(newDirection);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [incomingDirection]);

  return currentDirection;
}
