import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line max-len
import { ReactComponent as ArrowLeftDouble } from '../../../../assets/icons/components/left-arrow-double.svg';
import type RootStore from '../../../../stores/RootStore';
import styles from './SaveDataComponent.module.scss';

interface SaveDataComponentProps {
  rootStore?: RootStore;
}

const SaveDataComponent: React.FC<SaveDataComponentProps> = observer(({ rootStore }) => {
  const navigate = useNavigate();
  
  const saveUserAnswers = (): void => {
    if (rootStore != null) {
      rootStore.userAnswersStore.saveAnswers(
        rootStore.questionnaireStore.currentPage,
        rootStore.userAnswersStore.userPageAnswers,
      ).then(() => {
        navigate('/dashboard');
      }).catch((error) => {
        console.error(error);
      });
    }
  };  

  return (
    <div className={styles.container} onClick={saveUserAnswers}>
      <div className={styles.navLink}>
        <ArrowLeftDouble/>
        <span>Save and complete later</span>
      </div>
    </div>
  );
});

export default inject('rootStore')(SaveDataComponent);
