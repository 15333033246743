import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import type RootStore from '../../../../stores/RootStore';
import { ReactComponent as ArrowRight } 
from '../../../../assets/icons/components/right-arrow.svg';

import styles from './InsightsBar.module.scss';

interface InsightsBarProps {
  rootStore?: RootStore;
}
const InsightsBar: React.FC<InsightsBarProps> = observer(({ rootStore }) => {
  const insights = {
    hasSubmitted: true,
    message: '',
    // message: 'You submitted your insights report on 25/05/2023 at 11:32am.',
  };

  return (
    <>
      <div className={styles.container}>
        <p>{insights.message}</p>
        {insights.hasSubmitted
          ? (
          <p>
            <NavLink  to="/insightsReport" className={styles.navLink}>
              View Insights Report &nbsp;
              <ArrowRight></ArrowRight>
            </NavLink>
          </p>
            )
          : (
          <p>
            <NavLink to="/insightsPreview" className={styles.navLink}>
              Preview Insights Report &nbsp;
              <ArrowRight></ArrowRight>
            </NavLink>
          </p>
            )}
      </div>
    </>
  );
});

export default inject('rootStore')(InsightsBar);