/* eslint-disable max-len */
import { NavLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { ReactComponent as TechniusLogo } from '../../assets/icons/components/Header/TechniusLogo.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/components/Header/icon_home.svg';
import { ReactComponent as InsightsIcon } from '../../assets/icons/components/Header/icon_insights.svg';

import styles from './Header.module.scss';
import { useLogout } from '../../hooks/useLogout';

export default function Header(): React.JSX.Element {
  const { instance: msalInstance } = useMsal();
  const { logout } = useLogout(msalInstance);
  const handleLogoutClick = (): void => {
    logout().catch((error: unknown) => {
      console.error('Logout failed:', error);
    });
  };

  return (
    <header className={styles.container}>
      <nav>
        <div>
          <NavLink to="/home" className={styles.navLink}>
            <TechniusLogo className={styles.techniusLogo} />
            <h3>Mission Technius</h3>
          </NavLink>
        </div>
        <div className={styles.icons}>
          <NavLink to="/dashboard" className={styles.navLink}>
            <HomeIcon className={styles.iconLink} />
            <span>Home</span>
          </NavLink>
          <NavLink to="/insightsReport" className={styles.navLink}>
            <InsightsIcon className={styles.iconLink} />
            <span>Insights</span>
          </NavLink>
          <span className={styles.navLink} onClick={handleLogoutClick}>
            Logout
          </span>
        </div>
      </nav>
    </header>
  );
}
