import Header from '../../components/Header/Header';

import styles from './My404Component.module.scss';

const My404Component: React.FC = () => {
  return (
    <div className={styles.container}>
      <Header></Header>
      <div className={styles.content}>
        <h1>404 - Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default My404Component;
