export enum API_ENDPOINTS {
  USER_DASHBOARD = '/user/dashboard',
  REGISTER_ON_LOGIN = '/auth/registerIfNotExists',
  QUESTIONNAIRES_PREFIX = '/questionnaires',
  USER = '/user',
  USER_PAGE_ANSWERS = '/user/save-page-answers',
}

export const reportPreviewData = {
  // eslint-disable-next-line max-len
  note: 'Review your answers – you can edit them if you like. \nDownload a copy by clicking on “Print” at the top of the page and share with your Trainer.',
  friends: 'My close friends or co-workers would say about me:',
};
