import styles from './Statement.module.scss';

interface StatementProps {
  prompt?: string | string[];
  topMargin?: string | null;
}

export default function Statement({
  prompt = '',
  topMargin = null,
}: StatementProps): React.JSX.Element {
  const containerStyle =
    topMargin !== null ? { marginTop: `${topMargin ?? '0'}em` } : {};
  return (
    <div className={styles.container} style={containerStyle}>
      <p>{prompt}</p>
    </div>
  );
}
